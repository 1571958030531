import React from 'react';
import './style.css';
import '../../index.css'


const Footer = () => {
  return (
    <footer className='paddingHorizontal'>
          <div className="backToTop">
            <a href="#">Natrag na vrh</a>
          </div>
          <div>
            <a href="https://www.instagram.com/klara.pavlov">
              Instagram
            </a>
            {/* <span className="linkDivider">/</span>
            <a href="https://www.instagram.com/klara.pavlov/">
              Facebook
            </a> */}
          </div>
          <p className="email">info@klarapavlov.com</p>
          <p className="footerCredits">
            © Klara Pavlov. All rights reserved 2024
          </p>
        </footer>
  )
}

export default Footer