import React, { useState, useEffect } from "react";
import { Routes, Route, Link, Navigate, useLocation } from "react-router-dom";
import { Cross as Hamburger } from "hamburger-react";
import "./App.css";
import "./index.css";
import Footer from "./components/Footer";

const Home = React.lazy(() => import("./pages/Home/index"));
const About = React.lazy(() => import("./pages/About/index"));
const Portfolio = React.lazy(() => import("./pages/Portfolio/index"));
const Contact = React.lazy(() => import("./pages/Contact/index"));

const options = [
  {
    path: "/home",
    name: "Početna",
  },
  {
    path: "/about",
    name: "O meni",
  },
  {
    path: "/portfolio",
    name: "Radovi",
  },
  {
    path: "/contact",
    name: "Kontakt",
    highlight: true,
  },
];

function App() {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const location = useLocation();

  const [selectedOption, setSelectedOption] = useState(location.pathname);
  const [isOpen, setOpen] = useState(false);

  const [stickyClass, setStickyClass] = useState("relative");

  useEffect(() => {
    setSelectedOption(location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    const stickNavbar = () => {
      let windowHeight = window.scrollY;
      setStickyClass(
        windowHeight > 500 ? "fixed top-0 left-0 z-50" : "relative"
      );
    };

    window.addEventListener("scroll", stickNavbar);

    return () => {
      window.removeEventListener("scroll", stickNavbar);
    };
  }, []);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
      setOpen(false);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    // Add or remove 'mobile-navigation-open' class based on isOpen state
    if (isOpen) {
      document.body.classList.add("mobile-navigation-open");
    } else {
      document.body.classList.remove("mobile-navigation-open");
    }
  }, [isOpen]);

  console.log(windowSize.height);

  return (
    <div className="App">
      <header
        id="header"
        /*className="headerContainer"*/
        className={`paddingHorizontal headerContainer ${stickyClass}`}
      >
        <div className="brandContainer">
          <h1 className="brandName">
            <Link to="/">Klara Pavlov</Link>
          </h1>
          {/*<h3 className="subtitle">photography</h3>*/}
        </div>
        <nav className="navigation">
          {windowSize.width >= 768 ? (
            <ul className="list">
              {options.map((option, index) => (
                <li
                  key={option.path}
                  className={
                    selectedOption === option.path ? "activeListItem" : null
                  }
                >
                  <Link
                    to={option.path}
                    onClick={() => {
                      setSelectedOption(option.path);
                    }}
                    className={
                      selectedOption === option.path ? "activeText" : null
                    }
                    style={{
                      backgroundColor: option.highlight ? "pink" : null,
                    }}
                  >
                    {option.name}
                  </Link>
                </li>
              ))}
            </ul>
          ) : (
            <div className="hamburgerContainer">
              <Hamburger toggled={isOpen} toggle={setOpen} easing="ease-in" />
            </div>
          )}
        </nav>
      </header>
      {windowSize.width < 768 && isOpen && (
        <div className="mobileNavigation" style={{ height: windowSize.height }}>
          <ul className="mobileList">
            {options.map((option, index) => (
              <li
                key={option.name + "mobileNavigation-option"}
                className={selectedOption === option.path ? "active" : null}
              >
                <Link
                  to={option.path}
                  onClick={() => {
                    setSelectedOption(option.path);
                    setOpen(!isOpen);
                  }}
                  className={selectedOption === option.path && "activeText"}
                >
                  {option.name}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      )}

      <Routes>
        <Route
          exact
          path="/"
          element={
            <React.Suspense fallback={<>...</>}>
              <Home />
            </React.Suspense>
          }
        />
        <Route
          path="/about"
          element={
            <React.Suspense fallback={<>...</>}>
              <About />
            </React.Suspense>
          }
        />
        <Route
          path="/portfolio/*"
          element={
            <React.Suspense fallback={<>...</>}>
              <Portfolio category={"all"} />
            </React.Suspense>
          }
        />
        <Route
          path="/contact"
          element={
            <React.Suspense fallback={<>...</>}>
              <Contact />
            </React.Suspense>
          }
        />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
